import React from 'react';
import * as styles from './PrimarySection.module.css';
import SectionSvg from './SectionSvg';
import {Container} from './visual';
// import sectionImg from '../images/line-primary.svg'

const PrimarySection = ({children}) => {
    return (
        <div className={styles.primarySection}>
            <div className={styles.primarySectionImg}>
                <SectionSvg className={styles.primarySectionImg} />
            </div>
            <div className={styles.primarySectionContent}>
                <Container>
                    {children}
                </Container>
            </div>
        </div>
    );
}


export default PrimarySection;