import React from 'react';
import * as styles from './HomeSectionTimeline.module.css';
import {TimelineItem, Container} from './visual';


const HomeSectionTimeline = ({timelineData = [], title}) => {

    const renderTimeline = (data) => data.map((item) => {
        return <TimelineItem {...item} key={item.time}/>
    });

    return (
        <section className={styles.sectionTimeline}>
            <Container>
                <h1 className={styles.sectionTimelineTitle}>{title}</h1>

                {renderTimeline(timelineData)}
            </Container>
        </section>
    )
}

export default HomeSectionTimeline;