import React from "react";
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import MainBanner from '../components/MainBanner';
import PrimarySection from '../components/PrimarySection';
import HomeSectionTech from '../components/HomeSectionTech';
import HomeSectionAbout from '../components/HomeSectionAbout';
import HomeSectionTimeline from '../components/HomeSectionTimeline';


const workTimelineData = [
  // {
  //   time: '2024 - súčasnosť',
  //   title: 'Webová aplikácia na konfiguráciu HW - React developer | Aliter technologies',
  //   text: 'Webová Dashboard SPA na konfiguráciu HW zariadení. React, Vite, Typescript, MUI, TanstackTable. Komunikácia s BE pomocou REST volaní Tanstack Query. Úlohy na projekte: Výber technológií pre FE, nastavenie a založenie projektu.'
  // },
  {
    time: '2023 - 2024',
    title: 'Webová stránka s CMS a klientskou zónou - Next.js, Node.js developer | Aliter Technologies',
    text: 'Implementácia webu (články, služby, kontaktný formulár, cenová kalkulačka, generovanie PDF, ...) a používateľského rozhrania webovej aplikácie (prihlasovanie, registrácia, privátna zóna). Frontend - Next.js, Typescript, SASS CSS modules - IDSK design komponenty. Backend - Strapi CMS, implementácia vlastných endpointov a operácií. Implementácia automatizovaných testov - Cypress. Úlohy na projekte: Návrh architektúry, výber technológií, dockerizácia riešenia, vývoj riešenia. Tvorba a spolupráca pri návrhu a implementácii CI/CD. Udržiavanie projektu.'
  },
  {
    time: '2023 - 2024',
    title: 'Interný nástroj Chat GPT - Next.js, Node.js developer | Aliter Technologies',
    text: 'Interná Chat GPT aplikácia s prihlasovaním, históriou chatov a manažmentom tokenov. Frontend - Next.js (13+), server actions, server components, Formik, MUI, Typescript. Implementácia histórie chatov, vlákno chatu používateľa. FAQ zóna manažovateľná z CMS. Backend - Strapi, Docker, Typescript, Azure OpenAPI. Implementácia endpointov na prácu s chatmi a správami používateľa, manažment tokenov používateľa, unit testy. Úlohy na projekte: Výber technológií, založenie a nastavenie projektu, hlavný developer, udržiavanie projektu.'
  },
  {
    time: '2023 - 2024',
    title: 'Interný portál a zasadací poriadok - Next.js, Node.js developer | Aliter Technologies',
    text: 'Interný firemný portál s novinkami, udalosťami a dôležitými informáciami manažovateľnými cez CMS. Frontend - Next.js. Backend - Strapi. Úlohy na projekte: Výber technológií, založenie a nastavenie projektu, vedenie a code review pre junior developera, udržiavanie projektu.'
},
  {
      time: '2020 - 2022',
      title: 'MONSE monitoring seniorov - Senior React, Node.js developer | Monse',
      text: 'Implementácia dashboardu webovej aplikácie - React, Redux, MaterialUI. Implementácia backendových služieb a endpointov - Node.js, Express, Knex, OpenAPI, kafka.js, PostgreSQL, Winston, OTEL. Unit testy pomocou Mocha, Sinon. Úlohy na projekte: spolupráca na návrhu architektúry, služieb a rozhraní. FE, BE developer.'
  },
  {
    time: '2019 - 2020',
    title: 'WooCommerce Eshop - Monse',
    text: 'Vývoj e-shopu založeného na WordPress a WooCommerce. Vytvorenie jednoduchého Auth servera a endpointov pre iné aplikácie. Vývoj Wordpress pluginu pre technickíu podporu aplikácie. Integrácia na služby tretích strán. Spolupráca pri návrhu UI a riešení UX. Úlohy na projekte: PHP developer.'
  },
  {
      time: '2018 - 2020',
      title: 'PHP developer, JS developer - Aliter technologies',
      text: 'Vývoj prototypov a aplikácií v React (návrh UI, použitých technológií a rozhraní s BE). Participácia pri návrhu architekúry služieb. Správa a vývoj existujúcich aplikácií v Yii2. Správa a rozširovanie Wordpress webov. Spolupráca pri návrhu UI a riešení UX. (React, node.jS, Yii2, CSS, SASS, LESS, Docker, WordPress, WooCommerce, jQuery, REST, GRAPHQL)'
  },
  {
      time: '2016 - 2018',
      title: 'PHP, JS developer - freelancer',
      text: 'Vývoj prototypov a aplikácií v React. Participácia pri návrhu architektúry služieb. Správa a vývoj existujúcich aplikácií v Yii2 (PHP). Správa a rozširovanie WordPress webov. Spolupráca pri návrhu UI a riešení UX. (React, Node.js, Yii2, CSS, SASS, LESS, Docker, WordPress, WooCommerce, jQuery, REST, GraphQL)'
  },
  {
      time: '2014 – 06/2016',
      title: 'Developer hybridných mobilných aplikácií - freelancer (part time)',
      text: 'jQuery Mobile, Cordova, Phonegap, Intel XDK, CSS, HTML'
  },
  {
      time: '11/2012 – 06/2013',
      title: 'FE developer - Adbee digital (part time)',
      text: 'HTML, CSS, CakePHP 2, jQuery'
  }
];

const educationTimelineData = [
  {
      time: '2014 – 2016',
      title: 'MatFyz - Univerzita komenského (Mgr.)',
      text: 'Fakulta Matematiky, Fyziky a Informatiky, Bratislava Odbor: Aplikovaná informatika (Počítačová grafika)'
  },
  {
      time: '2011 – 2014',
      title: 'MatFyz - Univerzita komenského (Bc.)',
      text: 'Fakulta Matematiky, Fyziky a Informatiky, Bratislava Odbor: Aplikovaná informatika'
  },
  {
      time: '2007 – 2011',
      title: 'Gymnázium Andreja Vrábla, Levice',
  }
];

const coursesTimelineData = [
  {
      time: '18. 11. 2021',
      title: 'AWS Certified Developer - Associate',
      text: 'Oficiálna AWS certifikácia - Developer DVA-C01'
  },
  {
      time: '1. 3. 2021',
      title: 'AWS Certified Cloud Practitioner',
      text: 'Oficiálna AWS certifikácia - Practitioner CLF-C01'
  }
];

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="O mne"/>
      <MainBanner/>
      <PrimarySection>
          <HomeSectionAbout />
          <HomeSectionTech />
      </PrimarySection>

      <HomeSectionTimeline timelineData={workTimelineData} title="Pracovné skúsenosti" />
      <HomeSectionTimeline timelineData={educationTimelineData} title="Vzdelanie" />
      <HomeSectionTimeline timelineData={coursesTimelineData} title="Kurzy a certifikácie" />

    </Layout>
  )
}

export default IndexPage
