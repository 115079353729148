
import React from 'react';
import { Link } from 'gatsby';
import * as styles from './MainBanner.module.css';
import { Container } from './visual';
import * as ROUTES from '../common/routes';

const MainBanner = () => {
    return (
        <section className={styles.mainBanner}>
            <Container>
                <h1 className={styles.title}><span className={styles.titleText}>Branislav <span className={styles.titlePrimary}>Ballon</span></span></h1>
                <h2 className={styles.subtitle}><span className={styles.subtitleText}>JavaScript, PHP developer</span></h2>
                <Link className={styles.button} to={ROUTES.CONTACT}>kontakt</Link>
            </Container>
        </section>
    );
}

export default MainBanner;