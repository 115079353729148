import React from 'react';
import * as styles from './HomeSectionAbout.module.css';

const HomeSectionAbout = () => {
    return (
        <section className={styles.sectionAbout}>
            <h3 className={styles.sectionAboutTitle}>O mne</h3>
            <p className={styles.sectionAboutText}>
                Som full-stack Javascript vývojár (v minulosti aj PHP), ktorého baví objavovať nové technológie a neustále sa vzdelávať.
                Uprednostňujem prácu, ktorá je pokrytá testami, preto mám skúsenosti s end-to-end a Unit testami.
                Okrem programovania ma baví aj dizajn a používateľské rozhrania a vo Figme si rád navrhujem dizajny pre svoje projekty.
            </p>
            <p className={styles.sectionAboutText}>
                Snažím sa riadiť výrokom: “Skutočným umením je robiť veci jednoducho”. Podporujem občianske združenia a nápady z môjho okolia tvorbou webových stránok. Vo voľnom čase sa venujem rodine, chalupe, športu, cestovaniu a knihám.
            </p>
        </section>
    )
}

export default HomeSectionAbout;