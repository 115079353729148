import React from 'react';
import * as styles from './SectionSvg.module.css'

const SectionSvg = () => {
    return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="svg1340"
                version="1.1"
                viewBox="0 0 359.83334 37.33073"
                height="100%"
                width="100%">
            <defs
                id="defs1334" />
            <g

                transform="translate(77.863094,32.272507)"
                id="layer1">
                <path
                    className={styles.path}
                    id="path1109-5"
                    d="M 281.97024,-20.387277 H 229.05358 L 140.72182,-1.2675584 -1.3842146,-32.272507 -77.863094,-20.387277 V 5.0582216 c 119.944445,0 239.888894,0 359.833334,0 z"
                    style={{
                        // fill:"#ff5f6d",
                        fillOpacity:1,
                        stroke: "none",
                        strokeWidth: 0.264583,
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit:4,
                        strokeDasharray: "none",
                        strokeOpacity:1
                    }}
                />
            </g>
        </svg>


    );
}

export default SectionSvg;