import React from 'react';
import * as styles from './HomeSectionTech.module.css';
import {CardProgress, Row, Col} from './visual'

const cardsData = [
    {
        label: 'HTML / CSS',
        value: 90
    },
    {
        label: 'JS / TS',
        value: 80
    },
    {
        label: 'React',
        value: 80
    },
    {
        label: 'Next.js',
        value: 80
    },
    {
        label: 'NodeJS',
        value: 75
    },
    {
        label: 'PHP',
        value: 50
    },
]

const HomeSectionTech = () => {

    const renderCards = (data) => {
        return data.map(item => {
            return (
                <Col xs="6" sm="4" md="4" lg="2" key={item.label} className={styles.sectionTechCard}>
                    <CardProgress value={item.value} label={item.label} />
                </Col>
            )
        });
    }

    return (
        <section className={styles.sectionTech}>
            <h3 className={styles.sectionTechTitle}>Technológie</h3>
            <Row>
                {renderCards(cardsData)}
            </Row>

            <p className={styles.sectionTechAdditional}>
                React, Node.js, Next.js, Strapi, TypeScript, Docker, Cypress, CI/CD, Figma, MUI, REST API, CSS, SASS, GIT, Gitlab, SQL, Eslint, Prettier, GraphQL, Express, Mocha, Chai, Joi, Knex, Drizzle, Redux, i18n, Radix UI, Linux, JIRA.
            </p>
            <p className={styles.sectionTechAdditional}>
                <small>
                    PHP, WordPress, CakePHP, Yii, WooCommerce, Angular, VUE, Ionic, Gatsby, JAM stack, Leaflet, BEM, CSS modules, Bootstrap, PWA, jQuery, jQuery mobile, MicroPython, RPI, Code Igniter.
                </small>
            </p>
        </section>
    );
}

export default HomeSectionTech;